import React, { useState } from 'react'
import { createRoot } from 'react-dom/client'
import { setConfiguration } from 'react-grid-system'
import { ToastContainer } from 'react-toastify'

import { Web } from './views/components'

import 'react-toastify/dist/ReactToastify.css'

import '@/assets/styles/theme.scss'
import '@/assets/styles/main.scss'

const container = document.getElementById('app') as HTMLElement
const root = createRoot(container)

const appHeight = () => {
  document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
}

const supportsOrientationChange = 'onorientationchange' in window
const orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize'

window.addEventListener(orientationEvent, () => {
  setTimeout(() => {
    appHeight()
  }, 100)
})

appHeight()

const App = () => {
  const [isLightTheme, setIsLightTheme] = useState<boolean>(window.matchMedia('(prefers-color-scheme: light)').matches)
  document.setIsLightTheme = setIsLightTheme
  document.body.classList.add(isLightTheme ? 'light' : 'dark')

  return (
    <>
      <Web />
      <ToastContainer />
    </>
  )
}

setConfiguration({ gutterWidth: 24 })

root.render(<App />)
